import React from "react";

const NotFound = () => {
	return (
		<div className=" w-full h-screen flex justify-center items-center relative bg-gray-100 pl-[81px] pt-[10vh]">
			{/* <Header /> */ }
			<h1>This Page Still under development</h1>
		</div>

	);
};

export default NotFound;
