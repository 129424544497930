import React from "react";
import { Button, Text, Heading } from "./..";
import { default as ModalProvider } from "react-modal";

export default function DefaultModal({ isOpen, type, title, desc, onButtonClick, ...props }) {
	const handleButtonClick = () => {
		if (onButtonClick) {
			onButtonClick();
		}
	};

	return (
		<ModalProvider { ...props } style={ {
			overlay: {
				backgroundColor: isOpen ? "#D9D9D9CC" : "transparent",
			},
			content: {
				outline: "none",
			},
		} }
			appElement={ document.getElementById("root") } isOpen={ isOpen } className="w-[30%]">
			<div className="flex flex-col items-start justify-start w-full p-10 bg-white rounded-[10px]">
				<Heading size="lg" as="h1" className={ `!font-poppins !font-semibold ${ type === 'success' ? 'text-green-100' : type === 'error' ? 'text-red-A400' : 'text-black-900' }` }>
					{ title }
				</Heading>
				<Text as="p" className="w-[90%] mt-3 text-blue_gray-700">
					{ desc }
				</Text>
				<div className="flex justify-end w-full">
					<Button
						onClick={ handleButtonClick }
						size="4xl"
						className="mt-14 tracking-[0.40px] font-poppins uppercase font-semibold w-fit rounded-[10px] p-5"
					>
						OK
					</Button>
				</div>
			</div>
		</ModalProvider>
	);
}
