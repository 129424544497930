import React from "react";

const sizes = {
	"3xl": "text-[17px] font-semibold leading-[26px]",
	"2xl": "text-base font-semibold leading-5",
	"5xl": "text-xl font-semibold leading-[25px]",
	"4xl": "text-base md:text-lg font-semibold leading-[22px]",
	"7xl": "text-2xl font-extrabold",
	"6xl": "text-[22px] font-semibold",
	"9xl": "text-2xl md:text-3xl font-semibold leading-[37px]",
	"8xl": "text-[25px] font-semibold leading-[38px]",
	"15xl": "text-[75px] font-semibold leading-[113px]",
	"14xl": "text-[47px] font-semibold",
	"13xl": "text-[42px] font-bold",
	xl: "text-[15px] font-semibold",
	s: "text-xs font-bold",
	md: "text-[13px] font-semibold leading-4",
	"12xl": "text-[38px] font-semibold",
	xs: "text-[8px] font-bold",
	lg: "text-sm font-bold",
	"11xl": "text-[28px] md:text-[35px] font-semibold md:leading-[60px]",
	"10xl": "text-[26px] md:text-[32px] font-semibold md:leading-[100%]",
};

const Heading = ({ children, className = "", size = "2xl", as, ...restProps }) => {
	const Component = as || "h6";

	return (
		<Component className={ `text-black-900 font-inter ${ className } ${ sizes[size] }` } { ...restProps }>
			{ children }
		</Component>
	);
};

export { Heading };
