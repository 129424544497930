import React from "react";
import { Text, Heading, Button, Img } from "./..";
import { Link } from "react-router-dom";

export default function Footer({ ...props }) {
	return (
		<footer  { ...props } className="flex justify-center items-center w-full px-5 lg:px-14 py-10 bg-indigo-900">
			<div className="flex flex-col items-center justify-center w-full mx-auto lg:max-w-[1241px]">
				<div className="grid grid-cols-1 lg:grid-cols-4 w-full gap-5 md:gap-10">
					<div className="flex flex-col">
						<Heading size="7xl" as="h4" className="!text-white !font-poppins">
							Fintech Asset pro
						</Heading>
					</div>
					<div className="lg:col-span-2 flex justify-between lg:px-10">
						<div className="flex flex-col items-start justify-start h-full gap-3">
							<Text size="xl" as="p" className="!text-white !font-poppins mb-3 !font-semibold">
								<a href="#about-us">About us</a>
							</Text>
							{/* <Text size="xl" as="p" className="!text-gray-200_01 !font-poppins">
								<a href="#client">Client</a>
							</Text> */}
							<Text size="xl" as="p" className="!text-gray-200_01 !font-poppins">
								<a href="#vision-mision">Vision Mision</a>
							</Text>
							<Text size="xl" as="p" className="!text-gray-200_01 !font-poppins">
								<a href="#our-services">Our Services</a>
							</Text>
							{/* <Text size="xl" as="p" className="!text-gray-200_01 !font-poppins">
								<a href="#our-team">Our Team</a>
							</Text> */}
							<Link to="/guide">
								<Text size="xl" as="p" className="!text-gray-200_01 !font-poppins">
									FAQ
								</Text>
							</Link>
						</div>
						<div className="flex flex-col items-start justify-start w-[50%] gap-3">
							<Text size="2xl" as="p" className="!text-white !font-poppins  mb-3">
								Contact us
							</Text>
							<Text size="xl" as="p" className=" !text-gray-200_01 !font-poppins">
								No15-11, 15th Floor
								The Vertical Business Suite
								Tower A, Jalan Kerinchi
								Bangsar South 59200
								Kuala Lumpur, Malaysia
							</Text>
							<Text size="xl" as="p" className=" !text-gray-200_01 !font-poppins mt-3">
								+60322420101
							</Text>
						</div>
					</div>
					<div className="flex flex-row gap-5 justify-start lg:justify-center">
						<div className="flex flex-col items-center justify-start h-[34px] w-[34px]">
							<div className="flex flex-col items-center justify-start h-[34px] w-[34px] p-[11px] bg-white shadow-lg rounded-[50%]">
								<a href="https://www.facebook.com/share/ZgD8ph6N3qut7P9R/?mibextid=LQQJ4d" target="_blank" rel="noopener noreferrer">
									<Img src="images/img_facebook_1.svg" alt="facebookoneone" className="h-3 w-3" />
								</a>
							</div>
						</div>
						<Button color="white_A700" size="md" shape="circle" className="w-[34px]">
							<a href="https://www.instagram.com/fintechassetpro" target="_blank" rel="noopener noreferrer">
								<Img src="images/img_instagram_1.svg" />
							</a>
						</Button>
						<div className="flex flex-col items-center justify-start h-[34px] w-[34px]">
							<div className="flex flex-col items-start justify-center h-[34px] w-[34px] p-2.5 bg-white shadow-lg rounded-[50%]">
								<a href="https://www.twitter.com/Fintechassetpro" target="_blank" rel="noopener noreferrer">
									<Img src="images/img_twitter_1.svg" alt="twitteroneone" className="h-[13px] w-[13px] mt-px" />
								</a>
							</div>
						</div>
						<Button color="white_A700" size="md" shape="circle" className="w-[34px]">
							<a href="https://www.linkedin.com/company/fintech-asset-pro" target="_blank" rel="noopener noreferrer">
								<Img src="images/img_group_258.svg" />
							</a>
						</Button>
					</div>
				</div>
				<div className="text-white font-poppins text-xs lg:text-base font-light mt-10 md:mt-16">
					© 2024 FINTECH ASSET PRO ALL RIGHTS RESERVED.
				</div>
			</div>
		</footer>
	);
}
