import React, { useState, useEffect, useMemo } from 'react';
import { Img, SelectBox, Input, Heading, Text } from "..";
import { CloseSVG } from "../../assets/images";
import DetailsModal from '../DetailsModal';
import { reportDataExcel } from "services/report";
import * as XLSX from 'xlsx';

const dropDownOptions = [
	{ label: "Newest", value: "newest" },
	{ label: "Oldest", value: "oldest" },
];

const TableRow = ({ id, idGroup, period, totalRows, dateSubmitted, status }) => {
	const [isOpen, setIsOpen] = useState(false);
	const formatDate = (dateString) => {
		const date = new Date(dateString);
		const options = { day: '2-digit', month: 'short', year: 'numeric' };
		return new Intl.DateTimeFormat('en-US', options).format(date);
	};
	const handleCloseModal = () => {
		setIsOpen(false);
	};

	const handleDownloadExcel = async () => {
		try {
			const reportsData = await fetchData(idGroup);
			const excelData = reportsData;
			const ws = XLSX.utils.json_to_sheet(excelData);
			const wb = XLSX.utils.book_new();
			XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
			const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
			const blob = new Blob([excelBuffer], { type: 'application/octet-stream' });
			const url = URL.createObjectURL(blob);

			const anchor = document.createElement('a');
			anchor.href = url;
			anchor.download = 'report_data.xlsx';
			anchor.click();

			setTimeout(() => {
				URL.revokeObjectURL(url);
			}, 100);
		} catch (error) {
			console.error("Error downloading Excel:", error);
		}
	};

	const fetchData = async (id) => {
		try {
			const reportsData = await reportDataExcel(id);
			const reportingDataArray = [];
			for (let rowIdx = 0; rowIdx < reportsData.data.reportings.length; rowIdx++) {
				const reportingData = reportsData.data.reportings[rowIdx].reporting_data;
				if (reportingData && Object.keys(reportingData).length !== 0) {
					reportingDataArray.push(reportingData);
				}
			}
			reportingDataArray.sort((a, b) => {
				const keyToSortBy = 'serial_no';
				return a[keyToSortBy] - b[keyToSortBy];
			});
			return reportingDataArray;
		} catch (error) {
			console.error("Error fetching originators data:", error);
		}
	};

	return (
		<div className="grid grid-cols-4 w-full gap-y-5">
			<Text as="p" className="!text-blue_gray-900_06 tracking-[-0.14px] py-8">
				{ formatDate(period) }
			</Text>
			<Text as="p" className="!text-blue_gray-900_06 tracking-[-0.14px] text-center py-8">
				{ totalRows }
			</Text>
			<Text as="p" className="!text-blue_gray-900_06 tracking-[-0.14px] text-center py-8">
				{ formatDate(dateSubmitted) }
			</Text>
			<div className='flex justify-center items-center w-full'>
				<Img
					onClick={ () => setIsOpen(true) }
					src="images/img_carbon_view.svg"
					alt="vector_one"
					className="h-4 w-4 md:h-6 md:w-6 ml-2.5 my-[17px]"
				/>
				<Img
					onClick={ handleDownloadExcel }
					src="images/img_material_symbol_black_900.svg"
					alt="materialsymbol"
					className="h-4 w-4 md:h-6 md:w-6 ml-2.5 my-[17px]"
				/>
				{/* {
					status === 'Pending' ?
						< Img
							src="images/img_material_symbol_red_a700.svg"
							alt="materialsymbol"
							className="h-[24px] w-[24px] ml-2.5"
						/> :
						<div className='h-4 w-4 md:h-6 md:w-6  ml-2.5' />
				} */}
			</div>
			<DetailsModal id={ id } idGroup={ idGroup } isOpen={ isOpen } title='Thank You' desc='We have receive your organization detail' onButtonClick={ handleCloseModal } />
		</div>
	);
};

const Table = ({ data }) => {
	const [currentPage, setCurrentPage] = useState(1);
	const [itemsPerPage] = useState(50);
	const [searchTerm, setSearchTerm] = useState('');
	const [sortOption, setSortOption] = useState('oldest');
	const [sortedData, setSortedData] = useState([]);
	const handleSearch = (e) => {
		setSearchTerm(e);
		setCurrentPage(1);
	};

	const indexOfLastItem = currentPage * itemsPerPage;
	const indexOfFirstItem = data && data.length !== 0 ? indexOfLastItem - itemsPerPage : -1;
	const filteredData = useMemo(() => {
		return data ? data.filter(item => item.period.toLowerCase().includes(searchTerm.toLowerCase())) : [];
	}, [data, searchTerm]);
	const currentItems = sortedData.slice(indexOfFirstItem, indexOfLastItem);

	useEffect(() => {
		const sorted = [...filteredData].sort((a, b) => {
			const aDate = new Date(a.month + " " + a.year);
			const bDate = new Date(b.month + " " + b.year);
			if (sortOption.value === 'oldest') {
				return aDate - bDate;
			} else {
				return bDate - aDate;
			}
		});
		setSortedData(sorted);
	}, [filteredData, sortOption]);

	const paginate = (pageNumber) => setCurrentPage(pageNumber);

	return (
		<div className="flex flex-col items-center justify-start w-full bg-white shadow-2xl rounded-[30px] p-5 md:p-12 gap-12">
			{/* Search Input */ }
			<div className="flex flex-col md:flex-row gap-5 justify-between items-center  w-full">
				<Heading size="6xl" as="h2" className="tracking-[-0.22px]">
					All Files
				</Heading>
				<div className="flex flex-row justify-start gap-2 md:gap-5">
					<Input
						color="gray_50_02"
						size="xs"
						value={ searchTerm }
						placeholder="Search"
						onChange={ handleSearch }
						prefix={ <Img src="images/img_search_1.svg" alt="search 1" className="cursor-pointer w-5 h-5" /> }
						suffix={
							searchTerm?.length > 0 ? (
								<CloseSVG
									onClick={ () => setSearchTerm("") }
									height={ 27 }
									width={ 31 }
									fillColor="#7e7e7eff"
								/>
							) : null
						}
						className=" gap-2.5 text-blue_gray-200 tracking-[-0.12px] !rounded-[10px]"
					/>
					<SelectBox
						color="gray_50_02"
						size="sm"
						indicator={ <Img src="images/img_arrowdown.svg" alt="arrow_down" className="absolute w-5" /> }
						name="sortbynewest"
						placeholder="Sort by :"
						options={ dropDownOptions }
						onChange={ (value) => setSortOption(value) }
						className="w-[160px] gap-px tracking-[-0.12px] font-poppins px-10"
					/>
				</div>
			</div>

			{/* Table */ }
			<div className="flex flex-col items-center justify-start w-full">
				<div className="flex flex-col items-center justify-start w-full ">
					<div className="grid grid-cols-4 w-full">
						<Text as="p" className="!text-blue_gray-200 tracking-[-0.14px] pb-8">
							Cut Off Date
						</Text>
						<Text as="p" className="!text-blue_gray-200 tracking-[-0.14px]  text-center pb-8">
							Total Rows
						</Text>
						<Text as="p" className="!text-blue_gray-200 tracking-[-0.14px] text-center pb-8">
							Date of Submited
						</Text>
						<Text as="p" className="!text-blue_gray-200 tracking-[-0.14px] text-center pb-8">
							Action
						</Text>

					</div>
					<div className='h-px bg-gray-200 w-full'></div>
					<div className="flex flex-col items-center justify-start w-full">
						{ currentItems.map((item, index) => (
							<div className='w-full' key={ index }>
								<TableRow
									id={ item.id }
									idGroup={ item.id_group }
									period={ item.cut_off_date }
									totalRows={ item.total_loan }
									dateSubmitted={ item.created_at }
								/>
								<div className='h-px bg-gray-200 w-full'></div>
							</div>
						)) }
					</div>
				</div>
			</div>

			{/* Pagination */ }
			<div className="flex flex-row justify-between items-center w-full">
				<p className="text-blue_gray-200 md:text-base text-xs tracking-[-0.14px]">
					Showing data { indexOfFirstItem + 1 } to { Math.min(indexOfLastItem, filteredData.length) } of { filteredData.length } entries
				</p>
				<div className="flex gap-3">
					<div
						onClick={ currentPage > 1 ? () => paginate(currentPage - 1) : undefined }
						className="bg-gray-100_02 rounded-md font-medium border-gray-200 border border-solid h-6 w-6 flex justify-center items-center text-xs"
					>
						{ `<` }
					</div>
					{ Array.from({ length: Math.ceil(filteredData.length / itemsPerPage) }, (_, i) => (
						<div key={ i } onClick={ () => paginate(i + 1) } className={ `rounded-md font-medium border-gray-200 border border-solid h-6 w-6 flex justify-center items-center text-xs ${ currentPage === i + 1 ? 'bg-indigo-900 text-white' : 'bg-gray-100_02 text-black-900' } ` }>{ i + 1 }</div>
					)) }
					<div
						onClick={ currentPage < Math.ceil(filteredData.length / itemsPerPage) ? () => paginate(currentPage + 1) : undefined }
						className="bg-gray-100_02 rounded-md font-medium border-gray-200 border border-solid h-6 w-6 flex justify-center items-center text-xs"
					>
						{ '>' }
					</div>
				</div>
			</div>
		</div>
	);
};

export default Table;