import React from "react";
import { Text, Img, Heading } from "..";

const AboutUs = () => {
	return (
		<div id="about-us" className=" w-full flex lg:flex-row flex-col lg:gap-5 pt-10">
			<div className="flex-1 flex flex-col items-start justify-start gap-7">
				<Text size="5xl" as="p" className="!text-red-500  !font-poppins uppercase">
					About us
				</Text>
				<Heading size="11xl" as="h2" className="!font-poppins !leading-normal">
					Discover Our Story: Innovating Finance, Empowering Futures
				</Heading>
				<Text as="p" size="2xl" className="!text-black-900 !font-poppins !font-normal !leading-7 text-justify">
					Fintech Asset Pro Sdn Bhd is a pioneering force in the realm of
					business management consulting and services, dedicated to
					catalyzing growth, efficiency, and innovation for enterprises
					across diverse industries. With a strategic focus on business
					transformation and facilitation, alongside an extensive portfolio
					encompassing general trading of commodities and cuttingedge information technology support services, we empower
					organizations to navigate the complexities of today's dynamic
					marketplace with confidence and agility.
				</Text>
			</div>
			<div className="flex justify-center md:w-[40%]">
				<Img
					src="images/img_aboutus.png"
					alt="imageone_one"
					className="rounded-[10px] w-[70%]"
				/>
			</div>

		</div>
	);
};

export default AboutUs;