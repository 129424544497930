import React, { useState, useEffect } from "react";
import { validation } from '../../helpers';
import { Text, Button, CheckBox, Input, Heading, Img } from "../../components";
import { Link } from "react-router-dom";
import { login, verifyUser } from '../../services/auth';
import { useNavigate } from 'react-router';
import * as loader from "../../components/Loading/loading";

export default function LoginPage() {
	const navigate = useNavigate();
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [emailError, setEmailError] = useState('');
	const [passwordError, setPasswordError] = useState('');
	const [loginError, setLoginError] = useState('');
	const [showPassword, setShowPassword] = useState(false);
	const isFormValid = emailError === '' && passwordError === '' && email !== '' && password !== '';

	useEffect(() => {
		const urlParams = new URLSearchParams(window.location.search);
		const token = urlParams.get('token');
		if (token) {
			verifyToken(token);
		}
	}, []);

	const handleEmailChange = (e) => {
		setEmail(e);
		setEmailError(validation.email(e));
	};

	const handlePasswordChange = (e) => {
		setPassword(e);
		setPasswordError(validation.passwordLength(e));
	};

	const handleLogin = async () => {
		loader.showLoader();
		try {
			const isLoggedIn = await login(email, password);
			if (isLoggedIn) {
				const kycStatus = localStorage.getItem('kycStatus');
				kycStatus === "pending" ?
					navigate('/originator-form') : navigate("/originator");
			}
		} catch (error) {
			setLoginError(error);
		} finally {
			loader.hideLoader();
		}
	};

	const verifyToken = async (token) => {
		loader.showLoader();
		try {
			const userData = await verifyUser(token);
		} catch (error) {
			console.log("error verify user");
		} finally {
			loader.hideLoader();
		}
	};

	return (
		<div className="bg-white flex justify-center items-center lg:h-screen w-screen">
			<div className="grid grid-cols-1 lg:grid-cols-2 justify-between items-center w-full lg:max-w-[1241px] overflow-hidden">
				<Img src="images/logo.png" alt="logo" className="" />
				<div className="flex flex-col items-center justify-center gap-[17px] px-10 lg:px-20 mb-8 lg:mb-0">
					<div className="flex flex-col items-center justify-center w-full mt-[19px] gap-[13px]">
						<Heading size="9xl" as="h2" className="!text-blue_gray-900_03 text-center">
							Log in to your account
						</Heading>
						<Text size="xl" as="p" className="!text-blue_gray-500 text-center">
							Welcome back! Please enter your details.
						</Text>
					</div>
					<div className="flex flex-col items-start justify-start w-full gap-6">
						<div className="flex flex-col items-center justify-start w-full gap-5">
							<div className="flex flex-col items-start justify-start w-full gap-[7px]">
								<Text as="p" className="!text-blue_gray-700">
									Email
								</Text>
								<Input
									type="email"
									name="email"
									onChange={ handleEmailChange }
									placeholder="Enter your email"
									className="w-full border-blue_gray-100_02 border border-solid"
								/>
								{ emailError && <Text as="p" className="text-red-500">{ emailError }</Text> }
							</div>
							<div className="flex flex-col items-start justify-start w-full gap-[7px] relative">
								<Text as="p" className="!text-blue_gray-700">
									Password
								</Text>
								<Input
									type={ showPassword ? 'text' : 'password' }
									name="password"
									placeholder="••••••••"
									onChange={ handlePasswordChange }
									suffix={
										showPassword ?
											<Img
												src="images/img_eyes_close.svg"
												onClick={ () => setShowPassword(false) }
												className="cursor-pointer w-4 h-4"
											/> :
											<Img
												src="images/img_eyes.svg"
												onClick={ () => setShowPassword(true) }
												className="cursor-pointer w-4 h-4"
											/>
									}
									className="w-full border-blue_gray-100_02 border border-solid"
								/>
								{ passwordError && <Text as="p" className="text-red-500">{ passwordError }</Text> }
							</div>
						</div>
						<div className="flex flex-row justify-between items-center w-full">
							<CheckBox name="rememberme" label="Remember me" className="gap-2 text-left font-medium" />
							<div className="flex flex-row justify-center">
								<div className="flex flex-row justify-center w-full">
									<Link to="#" className="mt-0.5">
										<Text as="p" className="!text-indigo-900">
											Forgot password
										</Text>
									</Link>
								</div>
							</div>
						</div>
						{ loginError && <Text as="p" className="text-red-500">{ loginError }</Text> }
						<Button onClick={ handleLogin } disable={ !isFormValid } size="2xl" className="w-full ml-px font-medium !shadow-sm">
							Sign in
						</Button>

						<Link to="/register" >
							<Text as="p" className="!text-blue_gray-700">
								<span className="text-blue_gray-700">I don’t have account, </span>
								<span className="text-indigo-900">Register</span>
							</Text>
						</Link>
					</div>
				</div>
			</div>
		</div>
	);
}
