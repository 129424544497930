import React, { useState } from "react";
import { validation } from '../../helpers';
import { Text, Button, Input, Heading, Img } from "../../components";
import { changePassword } from '../../services/auth';
import { useNavigate } from 'react-router';
import * as loader from "../../components/Loading/loading";

export default function ChangePassword() {
	const navigate = useNavigate();
	const username = localStorage.getItem('username');
	const [oldPassword, setOldPassword] = useState('');
	const [oldPasswordError, setOldPasswordError] = useState('');
	const [password, setPassword] = useState('');
	const [passwordError, setPasswordError] = useState('');
	const [rePassword, setRePassword] = useState('');
	const [rePassError, setRePassError] = useState('');
	const [changePasswordError, setChangePasswordError] = useState('');
	const [showPassword, setShowPassword] = useState(false);
	const [showOldPassword, setShowOldPassword] = useState(false);
	const [showPasswordConfirm, setShowPasswordConfirm] = useState(false);
	const isFormValid = passwordError === '' && password !== '' && oldPasswordError === '' && oldPassword !== '' && rePassError === '' && rePassword !== '';

	const handleChangePassword = async () => {
		loader.showLoader();
		try {
			const isSuccess = await changePassword(username, oldPassword, password);
			if (isSuccess) {
				navigate('/');
			}
		} catch (error) {
			setChangePasswordError(error);
		} finally {
			loader.hideLoader();
		}
	};

	return (
		<div className="bg-white flex justify-center items-center lg:h-screen w-screen">
			<div className="grid grid-cols-1 lg:grid-cols-2 justify-between items-center w-full lg:max-w-[1241px] overflow-hidden">
				<Img src="images/logo.png" alt="logo" className="" />
				<div className="flex flex-col items-center justify-center gap-[17px] px-10 lg:px-20 mb-8 lg:mb-0">
					<div className="flex flex-col items-center justify-center w-full mt-[19px] gap-[13px]">
						<Heading size="9xl" as="h2" className="!text-blue_gray-900_03 text-center">
							Change your Password
						</Heading>
					</div>
					<div className="flex flex-col items-start justify-start w-full gap-6">
						<div className="flex flex-col items-center justify-start w-full gap-5">
							<div className="flex flex-col items-start justify-start w-full gap-[7px]">
								<Text as="p" className="!text-blue_gray-700">
									Old Password
								</Text>
								<Input
									type={ showOldPassword ? 'text' : 'password' }
									name="password"
									placeholder="••••••••"
									onChange={ (e) => {
										setOldPassword(e);
										setOldPasswordError(validation.passwordLength(e));
									} }
									suffix={
										showOldPassword ?
											<Img
												src="images/img_eyes_close.svg"
												onClick={ () => setShowOldPassword(false) }
												className="cursor-pointer w-4 h-4"
											/> :
											<Img
												src="images/img_eyes.svg"
												onClick={ () => setShowOldPassword(true) }
												className="cursor-pointer w-4 h-4"
											/>
									}
									className="w-full border-blue_gray-100_02 border border-solid"
								/>
								{ oldPasswordError && <Text as="p" className="text-red-500">{ oldPasswordError }</Text> }
							</div>
							<div className="flex flex-col items-start justify-start w-full gap-[7px]">
								<Text as="p" className="!text-blue_gray-700">
									New Password
								</Text>
								<Input
									type={ showPassword ? 'text' : 'password' }
									name="password"
									placeholder="••••••••"
									onChange={ (e) => {
										setPassword(e);
										setPasswordError(validation.passwordLength(e));
									} }
									suffix={
										showPassword ?
											<Img
												src="images/img_eyes_close.svg"
												onClick={ () => setShowPassword(false) }
												className="cursor-pointer w-4 h-4"
											/> :
											<Img
												src="images/img_eyes.svg"
												onClick={ () => setShowPassword(true) }
												className="cursor-pointer w-4 h-4"
											/>
									}
									className="w-full border-blue_gray-100_02 border border-solid"
								/>
								{ passwordError && <Text as="p" className="text-red-500">{ passwordError }</Text> }
							</div>
							<div className="flex flex-col items-start justify-start w-full pt-0.5 gap-1.5">
								<Text as="p" className="!text-blue_gray-700">
									Repeat New Password
								</Text>
								<Input
									type={ showPasswordConfirm ? 'text' : 'password' }
									name="password"
									placeholder="••••••••"
									onChange={ (e) => {
										setRePassword(e);
										setRePassError(validation.passwordMatch(password, e));
									} }
									suffix={
										showPasswordConfirm ?
											<Img
												src="images/img_eyes_close.svg"
												onClick={ () => setShowPasswordConfirm(false) }
												className="cursor-pointer w-4 h-4"
											/> :
											<Img
												src="images/img_eyes.svg"
												onClick={ () => setShowPasswordConfirm(true) }
												className="cursor-pointer w-4 h-4"
											/>
									}
									className="w-full border-blue_gray-100_02 border border-solid"
								/>
								{ rePassError && <Text as="p" className="text-red-500">{ rePassError }</Text> }
							</div>
						</div>

						{ changePasswordError && <Text as="p" className="text-red-500">{ changePasswordError }</Text> }
						<Button onClick={ handleChangePassword } disable={ !isFormValid } size="2xl" className="w-full ml-px font-medium !shadow-sm">
							Sign in
						</Button>
					</div>
				</div>
			</div>
		</div>
	);
}
