import React, { useState, useEffect, useRef } from "react";
import { Button, Text, Img, Heading } from "../../components";
import Header from "../../components/Header";
import { useNavigate } from 'react-router';
import DefaultModal from '../../components/DefaultModal';
import * as XLSX from 'xlsx';
import { submitAssets } from 'services/originator';
import * as loader from "../../components/Loading/loading";

export default function Assets() {
	const navigate = useNavigate();
	const [selectedFile, setSelectedFile] = useState(null);
	const [submitError, setSubmitError] = useState('');
	const [fileError, setFileError] = useState('');
	const [isOpen, setIsOpen] = useState(false);
	const [jsonData, setJsonData] = useState(null);
	const [isValidating, setIsValidating] = useState(false);

	const isFormValid = selectedFile && !fileError && !isValidating;

	const handleDownload = () => {
		const url = 'template.xlsx';
		const anchor = document.createElement('a');
		anchor.href = url;
		anchor.download = 'assets.xlsx';
		anchor.click();
	};

	const handleSubmitAssets = async () => {
		if (submitError === '') {
			loader.showLoader();
			try {
				const isSuccess = await submitAssets(jsonData);
				if (isSuccess) {
					setIsOpen(true);
					setSubmitError('');
				}
			} catch (error) {
				setSubmitError(error);
			} finally {
				loader.hideLoader();
			}
		}
	};

	const drop = useRef(null);

	const handleCloseModal = () => {
		setIsOpen(false);
		navigate('/dashboard');
	};

	useEffect(() => {
		const dropCurrent = drop.current;
		if (dropCurrent) {
			dropCurrent.addEventListener('dragover', handleDragOver);
			dropCurrent.addEventListener('drop', handleDrop);

			return () => {
				dropCurrent.removeEventListener('dragover', handleDragOver);
				dropCurrent.removeEventListener('drop', handleDrop);
			};
		}
	}, []);

	const handleDragOver = (e) => {
		e.preventDefault();
		e.stopPropagation();
		const { files } = e.dataTransfer;
		const allowedTypes = ["application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"];
		if (files.length === 1 && allowedTypes.includes(files[0].type)) {
			setSelectedFile(files[0]);
			handleFileUpload(files[0]);
		} else {
			setFileError("Please drop only .xlsx files.");
		}
	};

	const handleDrop = (e) => {
		e.preventDefault();
		e.stopPropagation();
		const { files } = e.dataTransfer;
		const allowedTypes = ["application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"];
		if (files.length === 1 && allowedTypes.includes(files[0].type)) {
			setSelectedFile(files[0]);
			handleFileUpload(files[0]);
		} else {
			setFileError("Please drop only .xlsx files.");
		}
	};

	const handleSelectFile = () => {
		const fileInput = document.getElementById('fileInput');
		fileInput.click();
	};
	const handleFileUpload = (e) => {
		const file = e;
		if (!file) {
			setSubmitError('No file selected');
			return;
		}
		const reader = new FileReader();
		setSubmitError('');
		setIsValidating(true);

		reader.onload = function (event) {
			try {
				const data = new Uint8Array(event.target.result);
				const workbook = XLSX.read(data, { type: 'array' });

				if (workbook.SheetNames.length > 0) {
					const sheetName = workbook.SheetNames[0];
					const worksheet = workbook.Sheets[sheetName];
					const json = XLSX.utils.sheet_to_json(worksheet, { defval: '' });

					const jsonLowerCase = json.map(obj => {
						const newObj = {};
						for (const key in obj) {
							if (obj.hasOwnProperty(key)) {
								newObj[key.toLowerCase()] = obj[key];
							}
						}
						return newObj;
					});

					const convertCommaToDot = (value) => {
						if (typeof value === 'string') {
							return parseFloat(value.replace(/,/g, ''));
						}
						return value;
					};

					const excelSerialToDate = (serial, index) => {
						const date = new Date((serial - 25569) * 86400 * 1000);
						if (isNaN(date.getTime())) {
							throw new Error(`Invalid date value in row ${ index + 1 }. Please check the disbursement date or maturity date columns.`);
						}
						return date.toISOString().split('T')[0];
					};

					const filteredJson = jsonLowerCase.filter(row => row['serial no'] && row['disbursement date'] && row['maturity date']);

					const jsonWithDatesAndNumbers = filteredJson.map((obj, index) => {
						return {
							...obj,
							"disbursement date": excelSerialToDate(obj["disbursement date"], index),
							"maturity date": excelSerialToDate(obj["maturity date"], index),
							"marhun value (rm)": convertCommaToDot(obj["marhun value (rm)"]),
							"financing amount (rm)": convertCommaToDot(obj["financing amount (rm)"]),
							"outstanding balance (rm)": convertCommaToDot(obj["outstanding balance (rm)"]),
							"profit amount (rm)": convertCommaToDot(obj["profit amount (rm)"]),
							"margin of financing (%)": convertCommaToDot(obj["margin of financing (%)"]),
							"profit rate per annum (%)": convertCommaToDot(obj["profit rate per annum (%)"]),
							"financing tenure (months)": convertCommaToDot(obj["financing tenure (months)"]),
							"remaining tenure (months) as at cut-off date": convertCommaToDot(obj["remaining tenure (months) as at cut-off date"]),
						};
					});

					setJsonData(jsonWithDatesAndNumbers);
					validateData(jsonWithDatesAndNumbers);
				} else {
					console.error('No sheets found in the workbook');
				}
			} catch (error) {
				setFileError(error.message);
			} finally {
				setIsValidating(false);
			}
		};
		reader.readAsArrayBuffer(file);
	};

	function validateData(jsonData) {
		const validationRules = {
			"serial no": "Int",
			"sag no / financing ref no": "String",
			"disbursement date": "Datetime",
			"maturity date": "Datetime",
			"marhun value (rm)": "Float",
			"financing amount (rm)": "Float",
			"outstanding balance (rm)": "Float",
			"profit amount (rm)": "Float",
			"margin of financing (%)": "Float",
			"profit rate per annum (%)": "Float",
			"financing tenure (months)": "Int",
			"remaining tenure (months) as at cut-off date": "Int",
			"surat akuan gadaian no": "String",
			"marhun descriptions": "String",
			"account in arrears/default : yes/no": "String",
			"kyc done : yes/no": "String"
		};

		const errors = [];
		function mapData(data) {
			return {
				"serial_no": data["serial no"],
				"financing_no": data["sag no / financing ref no"],
				"disbursement_date": data["disbursement date"],
				"maturity_date": data["maturity date"],
				"marhun_amount": data["marhun value (rm)"],
				"financing_amount": data["financing amount (rm)"],
				"outstanding_balance": data["outstanding balance (rm)"],
				"profit_amount": data["profit amount (rm)"],
				"margin_of_financing": data["margin of financing (%)"],
				"profit_rate_per_annum": data["profit rate per annum (%)"],
				"financing_tenure_months": data["financing tenure (months)"],
				"remaining_tenure_months": data["remaining tenure (months) as at cut-off date"],
				"mortgage_id": data["surat akuan gadaian no"],
				"marhun_description": data["marhun descriptions"],
				"account_in_arrears": data["account in arrears/default : yes/no"] === 'YES',
				"kyc_done": data["kyc done : yes/no"] === 'YES'
			};
		}
		for (let rowIdx = 0; rowIdx < jsonData.length; rowIdx++) {
			const rowData = jsonData[rowIdx];

			Object.entries(validationRules).forEach(([columnName, expectedType]) => {
				const cellValue = rowData[columnName];
				const actualType = typeof cellValue;

				if (expectedType === 'Int' && !Number.isInteger(cellValue)) {
					errors.push(`Please check Row ${ rowIdx + 1 } Column ${ columnName } : Should be an Number.`);
				} else if (expectedType === 'Float' && actualType !== 'number') {
					errors.push(`Please check Row ${ rowIdx + 1 } Column ${ columnName } : Should be a Number.`);
				} else if (expectedType === 'String' && actualType !== 'string') {
					errors.push(`Please check Row ${ rowIdx + 1 } Column ${ columnName } : Should be a String.`);
				}
			});
		}
		if (errors.length > 0) {
			const errorMessage = errors.join('<br>');
			setFileError(errorMessage);
			return;
		} else {
			setJsonData(jsonData.map(mapData));
			setFileError('');
			return;
		}
	}

	return (
		<div className="flex flex-col items-center justify-center w-full gap-10 bg-gray-50">
			<Header className="w-full" />
			<div className="min-h-[calc(100vh-80px)] w-full flex justify-center px-5 mb-10">
				<div className="h-fit flex flex-row justify-start lg:max-w-[1241px] mt-20 w-full py-8 lg:py-12 px-8 lg:px-20 border-blue_gray-100_03 border border-solid bg-white rounded-[10px]">
					<div className="flex flex-col items-start gap-2 justify-start lg:w-[50%]">
						<Heading size="5xl" as="h1" className="ml-px !text-blue_gray-900_04 !font-roboto !font-bold">
							Submit New List Assets
						</Heading>
						<div ref={ drop } onClick={ handleSelectFile } className="flex flex-row justify-end items-center w-full mt-10 gap-6 p-4 border-blue_gray-300_01 border border-dashed rounded-[10px]">
							<Img src="images/img_feather_upload_cloud.svg" alt="featherupload" className="h-12 w-12" />
							<div className="flex flex-col md:flex-row justify-start items-center w-[73%] mr-[35px] gap-4">
								<div className="flex flex-col items-start justify-start w-[65%] gap-2.5">
									<Text as="p" className="!text-blue_gray-900_04">
										{ selectedFile ? selectedFile.name : "Select a file or drag and drop here" }
									</Text>
									{
										!selectedFile &&
										<Text size="s" as="p" className="!text-blue_gray-300_01">
											.xlsx
										</Text>
									}
								</div>
								<label htmlFor="fileInput" className="cursor-pointer">
									<Button as="span" size="lg" className="tracking-[0.40px] uppercase font-medium min-w-[122px]">
										{ selectedFile ? 'Change' : 'Select file' }
									</Button>
								</label>
								<input
									type="file"
									id="fileInput"
									style={ { display: "none" } }
									accept=".xlsx"
									onChange={ (e) => { setSelectedFile(e.target.files[0]); handleFileUpload(e.target.files[0]); } }
								/>
							</div>
						</div>
						<div className="flex justify-end w-full">
							<Text onClick={ handleDownload } as="p" className="!text-indigo-900 tracking-[0.40px] uppercase underline">
								Download Template
							</Text>
						</div>
						{
							fileError &&
							<div>
								<Heading size="4xl" as="h3" className="mt-[25px] !text-red-A400_01">
									Submit assets failed do to:
								</Heading>
								<Text as="p" className="mt-[5px] !text-blue_gray-700 !font-normal">
									{ fileError }
								</Text>
							</div>
						}
						{ submitError && <Text as="p" className="text-red-500 mt-5">{ submitError }</Text> }
						<Button
							onClick={ handleSubmitAssets }
							disable={ !isFormValid }
							size="3xl"
							className="mt-[42px] tracking-[0.40px] uppercase font-medium w-full md:w-fit md:min-w-[158px]"
						>
							Submit
						</Button>
					</div>
				</div>
			</div>
			<DefaultModal isOpen={ isOpen } type='success' title='Success' desc='Congratulations, assets accepted.' onButtonClick={ handleCloseModal } />
		</div>
	);
}
