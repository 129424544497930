import React from "react";

const sizes = {
	"5xl": "text-base md:text-[22px] font-normal",
	xs: "text-[10px] font-normal leading-[14px]",
	lg: "text-sm font-medium leading-[17px]",
	"6xl": "text-[25px] font-medium",
	"7xl": "text-3xl font-medium",
	"8xl": "text-3xl md:text-[40px] font-medium md:leading-[70px]",
	s: "text-xs font-medium leading-[100%]",
	"2xl": "text-sm md:text-[17px] font-medium",
	"3xl": "text-base md:text-lg font-medium md:leading-[27px]",
	"4xl": "text-xl font-normal",
	xl: "text-sm md:text-base font-normal md:leading-5",
	md: "text-[13px] font-normal leading-4",
};

const Text = ({ children, className = "", as, size = "lg", ...restProps }) => {
	const Component = as || "p";
	const isString = typeof children === "string";
	const isArray = Array.isArray(children);

	const processedChildren = isString ? children.split("<br>").map((line, index) => <React.Fragment key={ index }>{ line }<br /></React.Fragment>) : children;

	return (
		<Component className={ `text-blue_gray-900_06 font-inter ${ className } ${ sizes[size] }` } { ...restProps }>
			{ processedChildren }
		</Component>
	);
};

export { Text };
