import { token } from 'constant/localStorage';

const API_URL = process.env.REACT_APP_API_URL;

export async function login(username, password) {
	try {
		const response = await fetch(`${ API_URL }/login`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({ username, password }),
		});

		if (!response.ok) {
			const errorData = await response.json();
			throw errorData.code_message;
		}
		const data = await response.json();
		localStorage.setItem('accessToken', data.data.token);
		localStorage.setItem('userID', data.data.id);
		localStorage.setItem('username', data.data.username);
		localStorage.setItem('kycStatus', data.data.kyc_status);
		return data;
	} catch (error) {
		throw error;
	}
}

export async function register(name, email, password, phone) {
	try {
		const response = await fetch(`${ API_URL }/register`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				username: email,
				fullname: name,
				password: password,
				phone: phone,
				email: email,
				role_id: "ROLE-EXTERNAL-USER",
				company: ""
			}),
		});

		if (!response.ok) {
			const errorData = await response.json();
			throw errorData.code_message;
		}
		const data = await response.json();
		return data;
	} catch (error) {
		throw error;
	}
}

export async function verifyUser(token) {
	try {
		const response = await fetch(`${ API_URL }/verify`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({ token }),
		});

		if (!response.ok) {
			const errorData = await response.json();
			throw errorData.code_message;
		}
		const data = await response.json();
		return data;
	} catch (error) {
		throw error;
	}
}

export async function changePassword(username, oldpassword, newpassword) {
	try {
		const response = await fetch(`${ API_URL }/changepassword`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				"Authorization": `Bearer ${ token() }`
			},
			body: JSON.stringify({
				username: username,
				oldpassword: oldpassword,
				newpassword: newpassword
			}),
		});
		if (!response.ok) {
			const errorData = await response.json();
			if (errorData.code === 400 && errorData.code_message === "Error get claim from authorization tokenClaim") {
				window.location.href = "/login";
			} else {
				throw errorData.code_message;
			}
		}
		const data = await response.json();
		return data;
	} catch (error) {
		throw error;
	}
}