import { token } from 'constant/localStorage';

const API_URL = process.env.REACT_APP_API_URL;

export async function reportData() {
	try {
		const response = await fetch(`${ API_URL }/reportings_group?limit=1000&page=1&filter=`, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				'Authorization': `Bearer ${ token() }`
			},
		});

		if (!response.ok) {
			const errorData = await response.json();
			if (errorData.code === 400 && errorData.code_message === "Error get claim from authorization tokenClaim") {
				window.location.href = "/login";
			} else {
				throw errorData.code_message;
			}
		}
		const data = await response.json();
		return data;
	} catch (error) {
		throw error;
	}
}

export async function detailReport(id) {
	try {
		const response = await fetch(`${ API_URL }/reportings_group/${ id }`, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				'Authorization': `Bearer ${ token() }`
			},
		});

		if (!response.ok) {
			const errorData = await response.json();
			if (errorData.code === 401 && errorData.code_type === "unAuthorized") {
				window.location.href = "/login";
			} else {
				throw errorData.code_message;
			}
		}
		const data = await response.json();
		return data;
	} catch (error) {
		throw error;
	}
}

export async function reportDataExcel(id) {
	try {
		const response = await fetch(`${ API_URL }/reportings-export?limit=0&page=0&filter=&group_id=${ id }`, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				'Authorization': `Bearer ${ token() }`
			},
		});

		if (!response.ok) {
			const errorData = await response.json();
			if (errorData.code === 400 && errorData.code_message === "Error get claim from authorization tokenClaim") {
				window.location.href = "/login";
			} else {
				throw errorData.code_message;
			}
		}
		const data = await response.json();
		return data;
	} catch (error) {
		throw error;
	}
}


export async function exportReport(id) {
	try {
		const response = await fetch(`${ API_URL }/reportings-export?limit=0&page=0&filter=&group_id=${ id }`, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				'Authorization': `Bearer ${ token() }`
			},
		});

		if (!response.ok) {
			const errorData = await response.json();
			if (errorData.code === 401 && errorData.code_type === "unAuthorized") {
				window.location.href = "/login";
			} else {
				throw errorData.code_message;
			}
		}
		const data = await response.json();
		return data;
	} catch (error) {
		throw error;
	}
}
