import React from "react";

export default function TermsConditions() {
	return (
		<div className=" w-full h-screen flex justify-center items-center relative bg-gray-100 pl-[81px] pt-[10vh]">

			<h1>This Page Still under development</h1>
		</div>

	);
}
