import React from "react";
import Footer from "../../components/Footer";
import MainHeader from "../../components/MainHeader";
import { Hero, Client, Testimoni, AboutUs, WhatWeDo, VisionMission } from "../../components/Homepage";

export default function HomePagePage() {

	return (
		<div className="flex flex-col items-center justify-start w-full gap-20 lg:gap-[103px] bg-gray-50">
			<div className="relative w-full">
				<MainHeader />
			</div>
			<div className="flex flex-col items-center justify-start w-full overflow-hidden lg:max-w-[1241px] px-5 mt-5 lg:mt-10">
				<Hero />
				{/* <Client /> */ }
				<div className="h-px w-full mt-5 bg-blue_gray-100 md:mt-10" />
				<AboutUs />
				<div className="h-px w-full bg-blue_gray-100 mt-5 md:mt-10" />
				<VisionMission />
				<div className="h-px w-full bg-blue_gray-100 mt-5 md:mt-10" />
				<WhatWeDo />
				<Testimoni />
			</div>
			<Footer />
		</div>
	);
}
