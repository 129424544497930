import React, { useState } from "react";
import Accordion from "components/Accordian";
import MainHeader from "components/MainHeader";
import { Heading } from "components";
import Footer from "components/Footer";

export default function GuidePage() {
	const faqItems = [
		{
			title: "REGISTRATION",
			list: [
				{
					question: 'How to register a new account on Fintech Asset Pro?',
					answer: `At this time we will provide you with information on how to easily register at Fintech Asset Pro, Please follow these steps:<br/>
            <ul class="list-decimal pl-10 pt-3">
                <li>Go to fintechassetpro.com.my then click Register.</li>
                <li>Fill in the fields with email name, username, then a password combination of min 8 characters (upper/lower case, symbols). After that, click if you have read and agree with the user terms. Then click Sign Up.</li>
                <li>Registration Verification is sent to the email address registered during registration.</li>
                <li>Please confirm your email to get a confirmation code. This is to validate your email.</li>
                <li>If the verification is successful, then it would direct to the Login page.</li>
                <li>If you want to login, please go to our main page then click Login.</li>
                <li>Please enter the email address and password as registered at Fintech Asset Pro. Then click Login.</li>
                <li>Congratulations you have successfully logged into Fintech Asset Pro.</li>
            </ul>
        `
				},
			]
		},
		{
			title: "KYC PROCESS",
			list: [
				{
					question: 'How to do KYC at Fintech Asset Pro?',
					answer: `At this time we will provide you with information on how to easily KYC at Fintech Asset Pro, Please follow these steps:<br/>
            <ul class="list-decimal pl-10 pt-3">
                <li>Please go to our main page then click Login.</li>
                <li>Please enter the email address and password as registered at Fintech Asset Pro. Then click Login.</li>
                <li>Once you have successfully logged into Fintech Asset Pro, go to the Originator tab then please fill in your organization data and make sure the data you input is correct, then click Submit.</li>
                <li>KYC Process Successful</li>
            </ul>
        `
				},
				{
					question: 'Slow KYC process? Haven\'t gotten any results from your KYC submission?',
					answer: 'You can contact our Customer Service via Email to inquire about the KYC process that has not been approved.'
				},
				{
					question: 'What if I do not KYC?',
					answer: 'If you don\'t do the KYC process then you can\'t do any transaction on Fintech Asset Pro.'
				},
				{
					question: 'What are the requirements for my KYC to be approved?',
					answer: `KYC (Know Your Customer) verification is one of the important stages in the Fintech Asset Pro account registration process. This stage is carried out with the aim of verifying the Koperasi identity. If you are experiencing problems in carrying out the KYC process, here is a variety of information about tips on how to pass KYC verification at Fintech Asset Pro that can help you<br/><br/>The following are the requirements and documents needed to make it easier for you to pass KYC verification at Fintech Asset Pro.
					 <ul class="list-decimal pl-10 pt-3">
                <li>Company Name</li>
                <li>Company Phone Number</li>
								<li>Company Address</li>
								<li>Registration SKM</li>
								<li>License</li>
								<li>Bank Name</li>
								<li>Bank Account Name</li>
								<li>Bank Account Number</li>
            </ul>`
				},
				{
					question: 'How long is the KYC process at Fintech Asset Pro?',
					answer: 'For the KYC process, it takes a maximum of 2x24 hours from the time you have done the KYC process.'
				},
			]
		},
		{
			title: "TRANSACTION",
			list: [
				{
					question: 'How to create a report at Fintech Asset Pro?',
					answer: `At this time we will provide you with information on how to easily create a report at Fintech Asset Pro, Please follow these steps:<br/>
            <ul class="list-decimal pl-10 pt-3">
                <li>Please login with your email and password</li>
                <li>Once login successfully, click the Report tab.</li>
                <li>Download the template and make sure the report is follow the template</li>
                <li>Select periode</li>
                <li>Upload the file .xlsx</li>
                <li>Then click Submit</li>
            </ul>
        `
				},
				{
					question: 'How to check the uploaded report?',
					answer: `At this time we will provide you with information on how to easily check the report at Fintech Asset Pro, Please follow these steps:<br/>
            <ul class="list-decimal pl-10 pt-3">
                <li>Please login with your email and password</li>
                <li>Once login successfully, click the Dashboard tab.</li>
                <li>Then you will see the total file uploaded and the list of the reports</li>
            </ul>
        `
				},
				{
					question: 'How to check the Batch?',
					answer: `At this time we will provide you with information on how to easily check the batch at Fintech Asset Pro, Please follow these steps: <br/>
            <ul class="list-decimal pl-10 pt-3">
                <li>Please login with your email and password</li>
                <li>Once login successfully, click the Dashboard tab.</li>
                <li>Click Total Batch tab</li>
                <li>Then you will see the list of Batch</li>
            </ul>
        `
				},
			]
		},
	];
	return (
		<div className="flex flex-col items-center justify-start w-full gap-20 lg:gap-[103px] bg-gray-50">
			<div className="relative w-full">
				<MainHeader />
			</div>
			<div className="flex flex-col justify-start w-full overflow-hidden lg:max-w-[1241px] px-5 mt-10 lg:mt-20">
				<Heading size="9xl" as="h1" className="!text-blue_gray-900_03 text-center mb-10">
					Frequently Asked Questions
				</Heading>
				{ faqItems.map((item, index) => (
					<div key={ index } className="accordion-item border-b w-full mt-10">
						<Heading size="8xl" as="p" className="!text-blue_gray-900_03">
							{ item.title }
						</Heading>
						<Accordion items={ item.list } />
					</div>
				)) }
			</div>
			<Footer />
		</div>
	);
}
