import React from "react";
import { Text, Img, Heading } from "..";
import { Swiper, SwiperSlide } from 'swiper/react';
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

const Testimoni = () => {
	const client = [
		{
			image: 'images/aix.png',
			name: 'Asia International Exchanges (AIX)',
			desc: "This collaboration will leverage FAP's strengths in sales, marketing, business structuring, and blockchain technology. FAP will spearhead AIX's sales and marketing efforts, driving growth and market penetration. Additionally, FAP will focus on enhancing AIX's blockchain technology and security token offering (STO) capabilities, ensuring robust, secure, and innovative solutions for AIX's global user base. This partnership positions AIX for significant advancements in the crypto exchange market.",
			link: 'https://aix.asia/'
		},
		{
			image: 'images/kiwf.png',
			name: 'Khadijah International WAQF Foundation',
			desc: "Fintech Asset Pro (FAP) has established a strategic partnership with the Khadijah International WAQF Foundation (KIWF). In this collaboration, KIWF leads the frontline efforts in zakat collection, working closely with FAP to streamline and expand zakat collection efforts worldwide. This partnership promotes greater impact and community support, ensuring efficient and transparent transactions.",
			link: 'https://sedeqahtech.aix.asia/'
		},
		{
			image: 'images/rumahzakat.png',
			name: 'Rumah Zakat Indonesia',
			desc: "Fintech Asset Pro (FAP) has established a strategic partnership with Rumah Zakat Indonesia. In this collaboration, Rumah Zakat Indonesia has appointed FAP to enhance their global outreach. FAP serves as the bridge and platform provider for global zakat collection, enabling efficient and transparent transactions to support Rumah Zakat's mission of promoting community welfare.",
			link: 'https://sedeqahtech.aix.asia/'
		},
	];


	return (
		<div id="our-team" className="flex flex-col items-start justify-start w-full pt-20">
			<div className="flex flex-row justify-between items-center w-full mx-auto">
				<div className="flex flex-col items-start justify-start gap-7">
					<Text size="5xl" as="p" className="!text-red-500 !font-poppins uppercase">
						Strategic Partnership
					</Text>
					<Heading size="11xl" as="h2" className="!font-poppins !leading-normal">
						Driving Success Together:
						<br />
						Partnering to Empower Your Vision
					</Heading>
				</div>
			</div>
			<div
				className="grid grid-cols-1 lg:grid-cols-3 gap-10 py-5"
			>
				{ client.map((data, index) => (
					<div onClick={ () => window.open(data.link, "_blank") } LKey={ index } className="flex flex-col items-center  bg-white shadow-lg rounded-md gap-5 p-10">
						<Img
							src={ data.image }
							alt="circleimage"
							className="h-[60px]"
						/>
						<Text size="xl" as="p" className="text-center !font-semibold !font-poppins mt-5">
							{ data.name }
						</Text>
						<Text className="!text-gray-700_01 !font-poppins">
							{ data.desc }
						</Text>
					</div>
				)) }
			</div>
		</div >
	);
};

export default Testimoni;