import { Img, Text } from 'components';
import React, { useState } from 'react';

function Accordion({ items }) {
	const [activeIndex, setActiveIndex] = useState(null);

	const onItemClick = (index) => {
		setActiveIndex(index === activeIndex ? null : index);
	};

	return (
		<div className="accordion w-full ">
			{ items.map((item, index) => (
				<div key={ index } className="accordion-item border-b w-full">
					<div onClick={ () => onItemClick(index) } className='flex justify-between items-center'>
						<Text as="p" size="3xl"
							className='accordion-title !font-semibold py-4 px-6 cursor-pointer'
						>
							{ item.question }
						</Text>
						<Img src="images/img_arrowdown.svg" alt="arrow_down" className=" w-5" />
					</div>
					<div
						className={ `accordion-content px-6 text-gray-700_01 py-4 ${ index !== activeIndex && 'hidden' }` }
						dangerouslySetInnerHTML={ { __html: item.answer } }
					/>
				</div>
			)) }
		</div>
	);
}

export default Accordion;
