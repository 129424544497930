import React from "react";
import { useRoutes } from "react-router-dom";
import NotFound from "pages/NotFound";
import Register from "pages/Register";
import HomePage from "pages/HomePage";
import OriginatorForm from "pages/OriginatorForm";
import OriginatorReview from "pages/OriginatorReview";
import Login from "pages/Login";
import Dashboard from "pages/Dashboard";
import TermsConditions from "pages/TermConditions";
import GuidePage from "pages/Guide";
import RequestRedeem from "pages/RequestRedeem";
import Assets from "pages/Assets";
import ChangePassword from "pages/ChangePassword";

const ProjectRoutes = () => {
	let element = useRoutes([
		{ path: "*", element: <NotFound /> },
		{
			path: "/",
			element: <HomePage />,
		},
		{
			path: "register",
			element: <Register />,
		},
		{
			path: "login",
			element: <Login />,
		},
		{
			path: "originator-form",
			element: <OriginatorForm />,
		},
		{
			path: "originator",
			element: <OriginatorReview />,
		},
		{
			path: "dashboard",
			element: <Dashboard />,
		},
		{
			path: "assets",
			element: <Assets />,
		},
		{
			path: "terms-conditions",
			element: <TermsConditions />,
		},
		{
			path: "guide",
			element: <GuidePage />,
		},
		{
			path: "request-redeem",
			element: <RequestRedeem />,
		},
		{
			path: "change-password",
			element: <ChangePassword />,
		},
	]);

	return element;
};

export default ProjectRoutes;
