import { loader } from "react-global-loader";

const showLoader = () => {
  loader.show();
  document.body.style.overflow = "hidden";
};

const hideLoader = () => {
  loader.hide();
  document.body.style.overflow = "auto";
};

export {showLoader, hideLoader};