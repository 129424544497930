import React, { useState, useEffect } from "react";
import { Text, Img, Heading } from "../../components";
import { Table, TableBatch, TableRedeem } from "../../components/Dashboard";
import Header from "../../components/Header";
import { reportData } from "services/report";
import { batchData } from "services/batch";
import { redeemData } from "services/redeem";

export default function Dashboard() {
	const [activeTab, setActiveTab] = useState('uploaded');
	const [data, setData] = useState(null);
	const [dataBatch, setDataBatch] = useState(null);
	const [dataRedeem, setDataRedeem] = useState(null);

	const fetchData = async () => {
		try {
			const reportsData = await reportData();
			setData(reportsData.data);
		} catch (error) {
			console.error("Error fetching assets data:", error);
		}
	};

	const fetchDataBatch = async () => {
		try {
			const batchsData = await batchData();
			setDataBatch(batchsData.data);
		} catch (error) {
			console.error("Error fetching batch data:", error);
		}
	};

	const fetchDataRedeem = async () => {
		try {
			const redeemsData = await redeemData();
			setDataRedeem(redeemsData.data);
		} catch (error) {
			console.error("Error fetching redeem data:", error);
		}
	};

	useEffect(() => {
		fetchData();
		fetchDataBatch();
		fetchDataRedeem();
	}, []);

	return (

		<div className="flex flex-col items-center justify-start w-full pb-[84px] gap-[34px] bg-gray-50">
			<Header className="w-full z-10" />
			{ data && dataBatch && dataRedeem ? (
				<div className="flex flex-col items-center justify-center md:justify-start w-full gap-[31px] max-w-[1241px] mt-14 md:mt-20 px-5">
					<div className="lg:h-[175px] grid grid-cols-3 w-full relative rounded-xl lg:rounded-[30px] bg-white shadow-2xl">
						<div onClick={ () => setActiveTab('uploaded') } className={ `flex flex-col md:flex-row justify-start items-center gap-2 lg:gap-5 w-full p-3 lg:p-[33px] rounded-l-xl lg:rounded-l-[30px] lg:pl-20 ${ activeTab === 'uploaded' ? 'bg-green-A100_01' : 'bg-white' }` }>
							<div>
								<div className="flex items-center justify-center h-12 w-12 lg:w-[98px] lg:h-[98px] bg-gradient1 rounded-[50%] relative">
									<Img
										src="images/img_vuesax_linear_profile_2user.svg"
										alt="vuesaxlinear"
										className="h-6 lg:h-[49px]"
									/>
								</div>
							</div>
							<div className="flex flex-col text-center md:text-left gap-[5px]">
								<Text as="p" className="md:!text-base !text-xs !text-gray-500_01 tracking-[-0.14px] !font-poppins !font-normal">
									Total Assets Uploaded
								</Text>
								<Heading size="10xl" as="h1" className="!text-blue_gray-900_02 tracking-[-0.32px]">
									{ data.count }
								</Heading>
							</div>
						</div>

						<div onClick={ () => setActiveTab('batch') }
							className={ `flex flex-col md:flex-row justify-center md:justify-start items-center gap-2 lg:gap-5 w-full lg:p-[33px] rounded-xl lg:rounded-[30px] p-3 lg:pr-20  ${ activeTab === 'batch' ? 'bg-green-A100_01' : 'bg-white' }` }>
							<div>
								<div className="flex items-center justify-center h-12 w-12 lg:w-[98px] lg:h-[98px] bg-gradient1 rounded-[50%] relative">
									<Img
										src="images/img_vuesax_linear_profile_2user.svg"
										alt="vuesaxlinear"
										className="h-6 lg:h-[49px]"
									/>
								</div>
							</div>
							<div className="flex flex-col text-center md:text-left gap-[5px]">
								<Text as="p" className="md:!text-base !text-xs !text-gray-500_01 tracking-[-0.14px] !font-poppins !font-normal">
									Total Batch
								</Text>
								<Heading size="10xl" as="h1" className="!text-blue_gray-900_02 tracking-[-0.32px]">
									{ dataBatch.count }
								</Heading>
							</div>
						</div>

						<div onClick={ () => setActiveTab('redeem') }
							className={ `flex flex-col md:flex-row justify-center md:justify-start items-center gap-2 lg:gap-5 w-full lg:p-[33px] rounded-r-xl lg:rounded-r-[30px] p-3 lg:pr-20  ${ activeTab === 'redeem' ? 'bg-green-A100_01' : 'bg-white' }` }>
							<div>
								<div className="flex items-center justify-center h-12 w-12 lg:w-[98px] lg:h-[98px] bg-gradient1 rounded-[50%] relative">
									<Img
										src="images/img_vuesax_linear_profile_2user.svg"
										alt="vuesaxlinear"
										className="h-6 lg:h-[49px]"
									/>
								</div>
							</div>
							<div className="flex flex-col text-center md:text-left gap-[5px]">
								<Text as="p" className="md:!text-base !text-xs !text-gray-500_01 tracking-[-0.14px] !font-poppins !font-normal">
									Total Redeem
								</Text>
								<Heading size="10xl" as="h1" className="!text-blue_gray-900_02 tracking-[-0.32px]">
									{ dataRedeem.count }
								</Heading>
							</div>
						</div>
					</div>
					{
						activeTab === 'uploaded' ?
							<Table data={ data.reportings } /> : activeTab === 'batch' ?
								<TableBatch data={ dataBatch.batchings } /> :
								<TableRedeem data={ dataRedeem.redeems } />
					}

				</div>
			) : (
				<div className="flex w-full h-[90vh] justify-center items-center">Loading...</div>
			) }
		</div >
	);
}
