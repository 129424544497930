export const email = email => {
	if (!email) return "Email is required";
	const regExp = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	return regExp.test(email) ? "" : "Invalid email format";
};

export const passwordLength = password => {
	if (!password) return "Password is required";
	if (password.length < 6) return "Password must be more than 6 characters";
	const complexityRegExp = /(?=.*[a-z])(?=.*[A-Z])/;
	if (!complexityRegExp.test(password)) {
		return 'Password must contain at least lowercase letter and one uppercase letter';
	}

	return "";
};

export const phoneNumber = phone => {
	if (!phone) return "Phone Number is required";

	if (phone.length < 9 && phone.length > 20) return "Invalid phone number";
	const re = /^\d{9,20}$/;
	return re.test(phone) ? "" : "Invalid phone number";
};


export const passwordMatch = (password, confirmPassword) => {
	return password === confirmPassword ? "" : "Password doesn't match";
};

export const required = data => {
	return data ? "" : "This Field is required";
};