import React, { useState, useMemo, useEffect } from 'react';
import { Img, SelectBox, Input, Heading, Text } from "..";
import { CloseSVG } from "../../assets/images";
import { getExportRedeem } from 'services/redeem';
import * as XLSX from 'xlsx';
import * as loader from "../Loading/loading";

const filterOptions = [
	{ label: "Newest", value: "newest" },
	{ label: "Oldest", value: "oldest" },
];

const TableRow = ({ no, submitDate, totalRow, status, redeemsId }) => {
	const handleDownloadExcel = async () => {
		loader.showLoader();

		try {
			const reportsData = await getExportRedeem(redeemsId);
			const redeemHistories = reportsData.data.redeem_history_data.redeem_histories;

			const flattenedData = [];

			redeemHistories.forEach(history => {
				const batchId = history.batch_id;
				const prevOutstanding = history.previous_outstanding;
				const updatedOutstanding = history.updated_outstanding;

				const maxLength = Math.max(history.previous_data.length, history.updated_data.length);
				for (let i = 0; i < maxLength; i++) {
					flattenedData.push({
						BatchId: i === 0 ? batchId : '',
						Previous_Financing_No: history.previous_data[i]?.financing_no || '',
						Updated_Financing_No: history.updated_data[i]?.financing_no || '',
						Previous_Outstanding: i === 0 ? prevOutstanding : '',
						Updated_Outstanding: i === 0 ? updatedOutstanding : '',
					});
				}
			});

			const worksheet = XLSX.utils.json_to_sheet(flattenedData);
			const workbook = XLSX.utils.book_new();
			XLSX.utils.book_append_sheet(workbook, worksheet, "Data");
			XLSX.writeFile(workbook, "resume_redeem.xlsx");
		} catch (error) {
			console.error("Error downloading Excel:", error);
		} finally {
			loader.hideLoader();
		}
	};

	return (
		<div className="grid grid-cols-6 w-full gap-y-5 place-content-center">
			<div className="col-span-2 py-8 text-blue_gray-900_06 tracking-[-0.14px]">
				<Text as="p" className="hidden md:inline">{ no }</Text>
				<Text as="p" className="md:hidden">{ no.length > 10 ? `${ no.substring(0, 10) }...` : no }</Text>
			</div>
			<Text as="p" className="!text-blue_gray-900_06 tracking-[-0.14px] py-8 text-center">
				{ submitDate.split('T')[0] }
			</Text>
			<Text as="p" className="!text-blue_gray-900_06 tracking-[-0.14px] py-8 text-center">
				{ totalRow }
			</Text>
			<div className='py-8 w-full flex justify-center items-center'>
				<Text as="p" className={ `w-fit tracking-[-0.14px] text-center font-semibold ${ status === 'granted' ? '!text-teal-700' : '!text-yellow-800_01' }` }>
					{ status.charAt(0).toUpperCase() + status.slice(1) }
				</Text>
			</div>
			<div className='flex justify-center items-center w-full'>
				{
					redeemsId &&
					<Img
						onClick={ handleDownloadExcel }
						src="images/img_material_symbol_black_900.svg"
						alt="materialsymbol"
						className="h-4 w-4 md:h-6 md:w-6 ml-2.5 my-[17px]"
					/>
				}
			</div>
		</div >
	);
};

const TableRedeem = ({ data }) => {
	const [currentPage, setCurrentPage] = useState(1);
	const [itemsPerPage] = useState(20);
	const [searchTerm, setSearchTerm] = useState('');
	const [sortOption, setSortOption] = useState('oldest');
	const [sortedData, setSortedData] = useState([]);

	const handleSearch = (e) => {
		setSearchTerm(e);
		setCurrentPage(1);
	};

	// Pagination
	const indexOfLastItem = currentPage * itemsPerPage;
	const indexOfFirstItem = indexOfLastItem - itemsPerPage;
	const filteredData = useMemo(() => {
		return data ? data.filter(item => item.id.toLowerCase().includes(searchTerm.toLowerCase())) : [];
	}, [data, searchTerm]);

	useEffect(() => {
		const sorted = [...filteredData].sort((a, b) => {
			const aDate = new Date(a.created_at);
			const bDate = new Date(b.created_at);
			if (sortOption.value === 'oldest') {
				return aDate - bDate;
			} else {
				return bDate - aDate;
			}
		});
		setSortedData(sorted);
	}, [filteredData, sortOption]);
	const currentItems = sortedData.slice(indexOfFirstItem, indexOfLastItem);

	const paginate = (pageNumber) => setCurrentPage(pageNumber);

	return (
		<div className="flex flex-col items-center justify-start w-full bg-white shadow-2xl rounded-[30px] p-5 md:p-12 gap-12">
			{/* Search Input */ }
			<div className="flex flex-col md:flex-row gap-5 justify-between items-center  w-full">
				<Heading size="6xl" as="h2" className="tracking-[-0.22px]">
					All Files
				</Heading>
				<div className="flex flex-row justify-start gap-5">
					<Input
						color="gray_50_02"
						size="xs"
						placeholder="Search"
						value={ searchTerm }
						onChange={ handleSearch }
						prefix={ <Img src="images/img_search_1.svg" alt="search 1" className="cursor-pointer w-5 h-5" /> }
						suffix={
							searchTerm?.length > 0 ? (
								<CloseSVG
									onClick={ () => setSearchTerm("") }
									height={ 27 }
									width={ 31 }
									fillColor="#7e7e7eff"
								/>
							) : null
						}
						className="gap-2.5 text-blue_gray-200 tracking-[-0.12px] !rounded-[10px]"
					/>
					<SelectBox
						color="gray_50_02"
						size="sm"
						indicator={ <Img src="images/img_arrowdown.svg" alt="arrow_down" className="absolute w-5" /> }
						name="shortbynewest"
						placeholder="Sort by : "
						options={ filterOptions }
						onChange={ (value) => setSortOption(value) }
						className="w-[160px] gap-px tracking-[-0.12px] font-poppins"
					/>
				</div>
			</div>

			{/* Table */ }
			<div className="flex flex-col items-center justify-start w-full">
				<div className="flex flex-col items-center justify-start w-full ">
					<div className="grid grid-cols-6 w-full">
						<Text as="p" className="!text-blue_gray-200 tracking-[-0.14px] pb-8 col-span-2">
							Redeem No
						</Text>
						<Text as="p" className="!text-blue_gray-200 tracking-[-0.14px] pb-8 text-center">
							Date of Submited
						</Text>
						<Text as="p" className="!text-blue_gray-200 tracking-[-0.14px] pb-8 text-center">
							Total Rows
						</Text>
						<Text as="p" className="!text-blue_gray-200 tracking-[-0.14px] text-center pb-8">
							Status
						</Text>
						<Text as="p" className="!text-blue_gray-200 tracking-[-0.14px] text-center pb-8">
							Action
						</Text>

					</div>
					<div className='h-px bg-gray-200 w-full'></div>
					<div className="flex flex-col items-center justify-start w-full">
						{ currentItems.map((item, index) => (
							<div className='w-full' key={ index }>
								<TableRow
									no={ item.id }
									submitDate={ item.created_at }
									totalRow={ item.financing_no.length }
									status={ item.status }
									redeemsId={ item.redeem_history_id }
								/>
								<div className='h-px bg-gray-200 w-full'></div>
							</div>
						)) }
					</div>
				</div>
			</div>

			{/* Pagination */ }
			<div className="flex flex-row justify-between items-center w-full">
				<p className="text-blue_gray-200 md:text-base text-xs tracking-[-0.14px]">
					Showing data { indexOfFirstItem + 1 } to { Math.min(indexOfLastItem, filteredData.length) } of { filteredData.length } entries
				</p>
				<div className="flex gap-3">
					<div
						onClick={ currentPage > 1 ? () => paginate(currentPage - 1) : undefined }
						className="bg-gray-100_02 rounded-md font-medium border-gray-200 border border-solid h-6 w-6 flex justify-center items-center text-xs"
					>
						{ `<` }
					</div>
					{ Array.from({ length: Math.ceil(filteredData.length / itemsPerPage) }, (_, i) => (
						<div key={ i } onClick={ () => paginate(i + 1) } className={ `rounded-md font-medium border-gray-200 border border-solid h-6 w-6 flex justify-center items-center text-xs ${ currentPage === i + 1 ? 'bg-indigo-900 text-white' : 'bg-gray-100_02 text-black-900' } ` }>{ i + 1 }</div>
					)) }
					<div
						onClick={ currentPage < Math.ceil(filteredData.length / itemsPerPage) ? () => paginate(currentPage + 1) : undefined }
						className="bg-gray-100_02 rounded-md font-medium border-gray-200 border border-solid h-6 w-6 flex justify-center items-center text-xs"
					>
						{ '>' }
					</div>
				</div>
			</div>
		</div>
	);
};

export default TableRedeem;