import React, { useState } from "react";
import { Text, Img } from "./..";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { logout } from "constant/localStorage";

export default function Header({ ...props }) {
	const navigate = useNavigate();
	const location = useLocation();
	const kycStatus = localStorage.getItem('kycStatus');
	const [isOpen, setIsOpen] = useState(false);
	const [isOpenUpload, setIsOpenUpload] = useState(false);
	const [isMenuOpen, setMenuIsOpen] = useState(false);

	const profileOptions = [
		{ href: '/originator', label: 'Profile' },
		{ href: '/change-password', label: 'Change Password' },
		{ href: '/', label: 'Sign out' },
	];
	const uploadFileOptions = [
		{ href: '/assets', label: 'Assets' },
		{ href: '/request-redeem', label: 'Request Redeem' },
	];
	const menuOptions = [
		{ href: '/assets', label: 'Assets' },
		{ href: '/request-redeem', label: 'Request Redeem' },
		{ href: '/dashboard', label: 'Dashboard' },
		{ href: '/originator', label: 'Originator' },
		{ href: '/', label: 'Sign out' },
	];

	const handleMenuToggle = () => {
		setMenuIsOpen(!isMenuOpen);
	};

	const handleItemClick = (link) => {
		if (link.label === 'Sign out') {
			logout();
			navigate('/');
		} else if ((link.label === 'Profile' && kycStatus === "pending")) {
			navigate('/originator-form');
		} else {
			navigate(link.href);
		}
	};

	return (
		<header { ...props }>
			<div className="flex flex-row justify-center w-full p-1.5 bg-white shadow-xs fixed">
				<div className="flex flex-row justify-between items-center w-full px-5 lg:mr-2 lg:max-w-[1241px]">
					<Link to="/" >
						<Img src="images/logo_crop.png" alt="logo" className="w-[130px] md:w-[150px]" />
					</Link>
					<div className="lg:hidden">
						<button onClick={ handleMenuToggle }>
							<Img src="images/img_menu.svg" alt="menu" className="h-[24px] w-[24px]" />
						</button>
					</div>
					<div className={ `hidden lg:flex flex-row justify-between items-center ${ kycStatus === "pending" ? 'w-[40%]' : 'w-[30%]' }` }>
						<div className="relative">
							{
								kycStatus === "approved" ? (
									<Text size="4xl" as="p" onClick={ () => { setIsOpenUpload(!isOpenUpload); setIsOpen(false); } } className={ location.pathname === "/assets" || location.pathname === "/request-redeem" ? "!text-indigo-900 !font-semibold underline" : "!text-black-900" }>
										Upload File
									</Text>
								) : (
									<Text size="4xl" as="p" className="text-gray-500 cursor-not-allowed">
										Upload File
									</Text>
								)
							}
							{ isOpenUpload && (
								<div className="absolute right-0 mt-5 w-48 bg-white border border-gray-300 rounded-md shadow-lg">
									{ uploadFileOptions.map((link) => (
										<Text
											key={ link.href }
											size="xl" as="p"
											onClick={ () => handleItemClick(link) }
											className="block px-4 py-2 text-gray-800 hover:bg-blue_gray-600 hover:rounded-md !font-semibold hover:text-white"
										>
											{ link.label }
										</Text>
									)) }
								</div>
							) }
						</div>

						{
							kycStatus === "approved" ? (
								<Link to="/dashboard">
									<Text size="4xl" as="p" className={ location.pathname === "/dashboard" ? "!text-indigo-900 !font-semibold underline" : "!text-black-900" }>
										Dashboard
									</Text>
								</Link>
							) : (
								<Text size="4xl" as="p" className="text-gray-500 cursor-not-allowed">
									Dashboard
								</Text>
							)
						}
						{ kycStatus === "pending" &&
							<Link to="/originator-form">
								<Text size="4xl" as="p" className={ location.pathname === "/originator-form" ? "!text-indigo-900 !font-semibold underline" : "!text-black-900" }>
									Originator
								</Text>
							</Link>
						}
						<div className="relative">
							<Img onClick={ () => { setIsOpenUpload(false); setIsOpen(!isOpen); } } src="images/img_person.svg" alt="avatar" className="h-[50px] w-[50px]" />
							{ isOpen && (
								<div className="absolute right-0 mt-2 w-48 bg-white border border-gray-300 rounded-md shadow-lg">
									{ profileOptions.map((link) => (
										<Text
											key={ link.href }
											size="xl" as="p"
											onClick={ () => handleItemClick(link) }
											className="block px-4 py-2 text-gray-800 hover:bg-blue_gray-600 hover:rounded-md !font-semibold hover:text-white"
										>
											{ link.label }
										</Text>
									)) }
								</div>
							) }
						</div>
					</div>
				</div>
			</div>
			{ isMenuOpen && (
				<div className="fixed right-5 mt-2 top-14 w-40 bg-white border border-gray-300 rounded-md shadow-lg">
					{ menuOptions.map((link) => (
						<Text
							key={ link.href }
							size="xl" as="p"
							onClick={ () => handleItemClick(link) }
							className="block px-4 py-2 text-gray-800 hover:bg-blue_gray-600 hover:rounded-md !font-semibold hover:text-white"
						>
							{ link.label }
						</Text>
					)) }
				</div>
			) }
		</header>
	);
}
