import React from "react";
import PropTypes from "prop-types";

const shapes = {
	square: "rounded-[0px]",
	circle: "rounded-[50%]",
	round: "rounded",
};
const variants = {
	gradient: {
		yellow_A700_amber_500_01: "bg-yellow-800 text-black-900",
	},
	fill: {
		indigo_900: "bg-indigo-900 shadow-xl text-white",
		green_50_01: "bg-green-50_01 text-green-800",
		green_800_01: "bg-green-800_01 text-white",
		indigo_A700: "bg-indigo-A700",
		yellow_800_01: "bg-yellow-800 text-white",
		yellow_800: "bg-yellow-800 text-white",
		white_A700: "bg-white shadow-lg",
		pink_900_4c: "bg-pink-900_4c text-pink-900",
		indigo_900_7f: "bg-indigo-900_7f shadow-xl text-white",
		gray_100_05: "bg-gray-100_02 text-blue_gray-800",
		teal_A700_60: "bg-teal-A700_60 text-teal-700",
		gray_100_02: "bg-gray-100_02 text-blue_gray-800",
	},
};
const sizes = {
	"6xl": "h-[60px] lg:h-[82px] px-5 md:px-[35px] text-base lg:text-xl ",
	"5xl": "h-[63px] px-[35px] text-3xl",
	sm: "h-[30px] px-[3px]",
	md: "h-[34px] px-2.5",
	xl: "h-10 px-[35px] text-base",
	"2xl": "h-11 px-[35px] text-base",
	"3xl": "h-[50px] px-5 text-sm",
	"4xl": "h-[57px] px-[35px] text-base",
	lg: "h-9 px-4 text-sm",
	xs: "h-6 px-[19px] text-xs",
};

const Button = ({
	children,
	className = "",
	leftIcon,
	rightIcon,
	shape = "round",
	variant = "fill",
	size = "xs",
	color = "indigo_900",
	disable = false,
	...restProps
}) => {
	let dynamicColor = disable ? "indigo_900_7f" : color;
	return (
		<button
			disabled={ disable }
			className={ `${ className } flex items-center justify-center text-center cursor-pointer ${ (shape && shapes[shape]) || "" } ${ (size && sizes[size]) || "" } ${ (variant && variants[variant]?.[dynamicColor]) || "" }` }
			{ ...restProps }
		>
			{ !!leftIcon && leftIcon }
			{ children }
			{ !!rightIcon && rightIcon }
		</button>
	);
};


Button.propTypes = {
	className: PropTypes.string,
	children: PropTypes.node,
	leftIcon: PropTypes.node,
	rightIcon: PropTypes.node,
	shape: PropTypes.oneOf(["square", "circle", "round"]),
	size: PropTypes.oneOf(["6xl", "5xl", "sm", "md", "xl", "2xl", "3xl", "4xl", "lg", "xs"]),
	variant: PropTypes.oneOf(["gradient", "fill"]),
	color: PropTypes.oneOf([
		"yellow_A700_amber_500_01",
		"indigo_900",
		"green_50_01",
		"green_800_01",
		"indigo_A700",
		"yellow_800_01",
		"yellow_800",
		"white_A700",
		"pink_900_4c",
		"indigo_900_7f",
		"gray_100_05",
		"teal_A700_60",
		"gray_100_02",
	]),
};

export { Button };
