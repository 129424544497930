import React, { useState, useEffect } from "react";
import { Button, Heading, Text, Img } from "../../components";
import { default as ModalProvider } from "react-modal";
import { detailReport } from 'services/report';
import { formatAmount } from 'helpers/utils';
export default function DetailsModal({ id, idGroup, isOpen, onButtonClick, ...props }) {
	const [data, setData] = useState(null);

	const handleButtonClick = () => {
		if (onButtonClick) {
			onButtonClick();
		}
	};

	const handleCopyBatchNumber = (batchNumber) => {
		navigator.clipboard.writeText(batchNumber);
		alert("Report Number copied to clipboard!");
	};

	const fetchData = async () => {
		try {
			const response = await detailReport(idGroup);
			setData(response.data.reporting_data);
		} catch (error) {
			console.error('Error fetching data:', error);
		}
	};
	useEffect(() => {
		fetchData();
	}, [id]);

	return (
		<ModalProvider { ...props } style={ {
			overlay: {
				backgroundColor: isOpen ? "#D9D9D9CC" : "transparent",
			},
			content: {
				outline: "none",
			},
		} }
			appElement={ document.getElementById("root") } isOpen={ isOpen } className="w-[90%] lg:w-[40%]">
			{ data ? (
				<div className="flex flex-col items-start justify-start w-full py-5 px-8 md:x-16 md:py-10 bg-white rounded-[10px]">
					<div className="grid grid-cols-1 lg:grid-cols-2 gap-8 w-full">
						<div className="flex flex-col space-y-5 w-full">
							<div className="flex flex-row justify-start items-center w-full gap-2">
								<div className="flex flex-col items-start justify-start gap-[3px]">
									<Text size="xl" as="p" className="!text-blue_gray-700">
										Report Number:
									</Text>
									<Heading as="h1" className="break-all">{ data.id_group }</Heading>
								</div>
								<div onClick={ handleCopyBatchNumber } className="flex flex-col items-center justify-start h-5 w-5">
									<Img src="images/img_vector.svg" alt="vectorone_one" className="h-5 w-5" />
								</div>
							</div>
							<div className="flex flex-col items-start justify-start gap-[9px]">
								<Text size="xl" as="p" className="!text-blue_gray-700">
									Company Name:
								</Text>
								<Heading as="h2">{ data.company_name }</Heading>
							</div>
							<div className="flex flex-col items-start justify-start mt-[22px] gap-1">
								<Text size="xl" as="p" className="!text-blue_gray-700">
									Total Financing Amount
								</Text>
								<Heading as="h5">{ formatAmount(data.total_financing_active) }</Heading>
							</div>
						</div>
						<div className="space-y-5">
							<div className="flex flex-col items-start justify-start gap-[9px]">
								<Text size="xl" as="p" className="!text-blue_gray-700">
									Total Loan:
								</Text>
								<Heading as="h2">{ data.total_loan }</Heading>
							</div>

							<div className="flex flex-col items-start justify-start gap-[9px]">
								<Text size="xl" as="p" className="!text-blue_gray-700">
									Total Loan Tagged:
								</Text>
								<Heading as="h2">{ data.total_loan_tagged }</Heading>
							</div>
							<div className="flex flex-col items-start justify-start gap-[9px]">
								<Text size="xl" as="p" className="!text-blue_gray-700">
									Total Price Tagged
								</Text>
								<Heading as="h2">{ formatAmount(data.total_price_tagged) }</Heading>
							</div>
						</div>
					</div>

					<div className="flex justify-end w-full">
						<Button
							onClick={ handleButtonClick }
							size="3xl"
							className="mt-5 md:mt-14 tracking-[0.40px] font-poppins uppercase font-semibold min-w-[100px] rounded-[10px]"
						>
							OK
						</Button>
					</div>

				</div>
			) : (
				<div className="flex w-full h-[90vh] justify-center items-center">Loading...</div>
			) }
		</ModalProvider>
	);
}
