import React, { useState, useEffect, useRef } from "react";
import { Button, Text, Input, Img, TextArea, Heading, CheckBox } from "../../components";
import Header from "../../components/Header";
import { Link } from "react-router-dom";
import { useNavigate } from 'react-router';
import { validation } from '../../helpers';
import DefaultModal from '../../components/DefaultModal';
import { userID } from "constant/localStorage";
import { uploadFile, submitKyc, detailOriginator } from "services/originator";
import * as loader from "../../components/Loading/loading";

export default function OriginatorForm() {
	const navigate = useNavigate();
	const [companyName, setCompanyName] = useState('');
	const [companyPhone, setCompanyPhone] = useState('');
	const [companyAddress, setCompanyAddress] = useState('');
	const [skm, setSKM] = useState('');
	const [bankName, setBankName] = useState('');
	const [bankAccountName, setBankAccountName] = useState('');
	const [bankAccount, setBankAccount] = useState('');
	const [selectedFile, setSelectedFile] = useState(null);
	const [selectedFileName, setSelectedFileName] = useState(null);
	const [companyNameError, setCompanyNameError] = useState('');
	const [companyPhoneError, setCompanyPhoneError] = useState('');
	const [companyAddressError, setCompanyAddressError] = useState('');
	const [skmError, setSKMError] = useState('');
	const [bankNameError, setBankNameError] = useState('');
	const [bankAccountNameError, setBankAccountNameError] = useState('');
	const [bankAccountError, setBankAccountError] = useState('');
	const [fileError, setFileError] = useState('');
	const [checkbox, setCheckbox] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [submitError, setSubmitError] = useState('');
	const [isOpen, setIsOpen] = useState(false);
	const drop = useRef(null);

	const isFormValid =
		!companyNameError &&
		!companyPhoneError &&
		!companyAddressError &&
		!skmError &&
		!bankNameError &&
		!bankAccountNameError &&
		!bankAccountError &&
		!fileError &&
		checkbox &&
		companyName &&
		companyPhone &&
		companyAddress &&
		skm &&
		bankName &&
		bankAccountName &&
		bankAccount &&
		selectedFile &&
		checkbox;

	const handleSubmitKyc = async () => {
		loader.showLoader();
		try {
			const update = submitKyc(companyName, companyPhone, companyAddress, skm, selectedFile, bankName, bankAccountName, bankAccount, userID());
			if (update) {
				setIsOpen(true);
				setSubmitError('');
			}
		} catch (error) {
			setSubmitError(error);
		} finally {
			loader.hideLoader();
		}
	};

	const handleUploadFile = async (file) => {
		setIsLoading(true);
		try {
			const isSuccess = await uploadFile(file);
			if (isSuccess) {
				setSelectedFile(isSuccess.data.file_url);
				setSelectedFileName(file);
				setFileError('');
				setIsLoading(false);
			}
		} catch (error) {
			setFileError(error);
		}
	};

	const handleCloseModal = () => {
		setIsOpen(false);
		const id = userID();
		navigate(`/originator?id=${ id }`);
	};

	useEffect(() => {
		const dropCurrent = drop.current;
		if (dropCurrent) {
			dropCurrent.addEventListener('dragover', handleDragOver);
			dropCurrent.addEventListener('drop', handleDrop);

			return () => {
				dropCurrent.removeEventListener('dragover', handleDragOver);
				dropCurrent.removeEventListener('drop', handleDrop);
			};
		}
	}, []);


	const handleDragOver = (e) => {
		e.preventDefault();
		e.stopPropagation();
	};

	const handleDrop = (e) => {
		e.preventDefault();
		e.stopPropagation();
		const { files } = e.dataTransfer;
		const allowedTypes = ["application/pdf"];
		if (files.length === 1 && allowedTypes.includes(files[0].type)) {
			handleUploadFile(files[0]);
		} else {
			setFileError("Please drop only .pdf files.");
		}
	};
	const handleSelectFile = () => {
		const fileInput = document.getElementById('fileInput');
		fileInput.click();
	};

	const fetchData = async () => {
		try {
			const response = await detailOriginator(userID());
			if (response.data.registration_data.company !== '') {
				setCompanyName(response.data.registration_data.company);
			}
			if (response.data.registration_data.company_phone !== '') {
				setCompanyPhone(response.data.registration_data.company_phone);
			}
			if (response.data.registration_data.address !== '') {
				setCompanyAddress(response.data.registration_data.address);
			}
			if (response.data.registration_data.registrasi_skm !== '') {
				setSKM(response.data.registration_data.registrasi_skm);
			}
			if (response.data.registration_data.bank_name !== '') {
				setBankName(response.data.registration_data.bank_name);
			}
			if (response.data.registration_data.bank_account_name !== '') {
				setBankAccountName(response.data.registration_data.bank_account_name);
			}
			if (response.data.registration_data.bank_account_number !== '') {
				setBankAccount(response.data.registration_data.bank_account_number);
			}
		} catch (error) {
			console.error('Error fetching data:', error);
		}
	};
	useEffect(() => {
		fetchData();
	}, []);

	return (
		<div className="flex flex-col items-center justify-center w-full pb-10 lg:pb-20 gap-[73px] bg-gray-50 relative">
			<Header className="w-full mb-5 lg:mb-20" />
			<div className="flex flex-col items-center justify-start w-full gap-10 px-5 lg:gap-16 lg:max-w-[1241px]">
				<Heading size="9xl" as="h1" className="!text-blue_gray-900_03 text-center">
					Complete your organization account to continue using our product.
				</Heading>
				<div className="flex flex-col items-center justify-center mb-[11px] gap-10 lg:w-[76%] px-8 md:px-[55px] py-10 md:py-[63px] border-blue_gray-100_01 border border-solid bg-white rounded-[10px]">
					<div className="flex flex-col items-start justify-start w-full gap-[19px]">
						<div className="flex flex-col items-start justify-start w-full pt-0.5 gap-1.5">
							<Text as="p" className="!text-blue_gray-700">
								Company Name
							</Text>
							<Input
								type="text"
								value={ companyName }
								onChange={ (value) => {
									setCompanyName(value);
									setCompanyNameError(validation.required(value));
								} }
								placeholder="Enter your company name"
								className="w-full border-blue_gray-100_02 border border-solid"
							/>
							{ companyNameError && <Text as="p" className="text-red-500">{ companyNameError }</Text> }
						</div>
						<div className="flex flex-col items-start justify-start w-full pt-0.5 gap-1.5">
							<Text as="p" className="!text-blue_gray-700">
								Company Phone Number
							</Text>
							<Input
								type="tel"
								value={ companyPhone }
								onChange={ (value) => {
									setCompanyPhone(value);
									setCompanyPhoneError(validation.phoneNumber(value));
								} }
								placeholder="Enter your company phone number"
								className="w-full border-blue_gray-100_02 border border-solid"
							/>
							{ companyPhoneError && <Text as="p" className="text-red-500">{ companyPhoneError }</Text> }
						</div>
						<div className="flex flex-row justify-start w-full">
							<div className="flex flex-row justify-start w-full">
								<div className="flex flex-col items-start justify-start w-full pt-0.5 gap-1.5">
									<Text as="p" className="!text-blue_gray-700">
										Company Address
									</Text>
									<TextArea
										value={ companyAddress }
										onChange={ (value) => {
											setCompanyAddress(value);
											setCompanyAddressError(validation.required(value));
										} }
										placeholder="Enter your company address"
										className="w-full"
									/>
									{ companyAddressError && <Text as="p" className="text-red-500">{ companyAddressError }</Text> }
								</div>
							</div>
						</div>
						<div className="flex flex-col items-start justify-start w-full pt-0.5 gap-1.5">
							<Text as="p" className="!text-blue_gray-700">
								Registration SKM
							</Text>
							<Input
								value={ skm }
								onChange={ (value) => {
									setSKM(value);
									setSKMError(validation.required(value));
								} }
								placeholder="Enter your registration SKM"
								className="w-full border-blue_gray-100_02 border border-solid"
							/>
							{ skmError && <Text as="p" className="text-red-500">{ skmError }</Text> }
						</div>
						<div className="flex flex-row justify-start w-full">
							<div className="flex flex-col items-center justify-start w-full gap-1.5">
								<div className="flex justify-start w-full">
									<Text as="p" className="!text-blue_gray-700">
										License
									</Text>
								</div>
								<div ref={ drop } onClick={ handleSelectFile } className="flex flex-row justify-end items-center w-full gap-6 p-4 border-blue_gray-300_01 border border-dashed rounded-[10px]">
									<Img src="images/img_feather_upload_cloud.svg" alt="featherupload" className="h-12 w-12" />
									<div className="flex flex-col md:flex-row justify-evenly items-center w-[73%] mr-[35px] gap-4">
										<div className="flex flex-col items-start justify-start w-[62%] gap-[11px]">
											{ isLoading ?
												<div className="!font-poppins !text-stone-500 w-full">Loading...</div> :
												<Text as="p" className="!text-blue_gray-900_04 !font-poppins">
													{ selectedFileName ? selectedFileName.name : "Select a file or drag and drop here" }
												</Text>
											}
											{
												!selectedFile &&
												<Text size="s" as="p" className="!text-blue_gray-300_01 !font-poppins">
													.pdf
												</Text>
											}
										</div>
										<label htmlFor="fileInput" className="cursor-pointer">
											<Button
												size="lg"
												className="tracking-[0.40px] font-poppins uppercase font-medium min-w-[113px]"
											>
												{ selectedFile ? 'Change' : 'Select file' }
											</Button>
										</label>
										<input
											type="file"
											id="fileInput"
											style={ { display: "none" } }
											accept=".pdf"
											onChange={ (e) => handleUploadFile(e.target.files[0]) }
										/>
									</div>
								</div>
								{ fileError && <Text as="p" className="flex justify-start text-red-500 w-full">{ fileError }</Text> }
							</div>
						</div>
						<div className="flex flex-col items-start justify-start w-full gap-[7px]">
							<Text as="p" className="!text-blue_gray-700">
								Bank Name
							</Text>
							<Input
								type="text"
								value={ bankName }
								onChange={ (value) => {
									setBankName(value);
									setBankNameError(validation.required(value));
								} }
								placeholder="Enter bank name"
								className="w-full border-blue_gray-100_02 border border-solid"
							/>
							{ bankNameError && <Text as="p" className="text-red-500">{ bankNameError }</Text> }
						</div>
						<div className="flex flex-col items-start justify-start w-full gap-[7px]">
							<Text as="p" className="!text-blue_gray-700">
								Bank Account Name
							</Text>
							<Input
								type="text"
								value={ bankAccountName }
								onChange={ (value) => {
									setBankAccountName(value);
									setBankAccountNameError(validation.required(value));
								} }
								placeholder="Enter your bank account name"
								className="w-full border-blue_gray-100_02 border border-solid"
							/>
							{ bankAccountNameError && <Text as="p" className="text-red-500">{ bankAccountNameError }</Text> }
						</div>
						<div className="flex flex-col items-start justify-start w-full gap-[7px]">
							<Text as="p" className="!text-blue_gray-700">
								Bank Account Number
							</Text>
							<Input
								type="number"
								value={ bankAccount }
								onChange={ (value) => {
									setBankAccount(value);
									setBankAccountError(validation.required(value));
								} }
								placeholder="Enter your bank account number"
								className="w-full border-blue_gray-100_02 border border-solid"
							/>
							{ bankAccountError && <Text as="p" className="text-red-500">{ bankAccountError }</Text> }
						</div>
						<div className="flex flex-col md:flex-row justify-start md:items-center  gap-1.5">
							<CheckBox onChange={ (value) => setCheckbox(value) } name="readAgree" label="I have read and agree to the" className="!text-blue_gray-700 !font-poppins gap-2 text-left font-medium" />
							<Link to="/terms-conditions" className="text-indigo-900 font-inter underline font-medium">
								Terms & Conditions
							</Link>
						</div>
					</div>
					{ submitError && <Text as="p" className="text-red-500">{ submitError }</Text> }
					<Button onClick={ handleSubmitKyc } disable={ !isFormValid } size="2xl" className="w-full font-medium border-indigo-900 border border-solid !shadow-sm">
						SUBMIT
					</Button>
				</div>
			</div>
			<DefaultModal isOpen={ isOpen } title='Thank You' desc='We have receive your organization detail' onButtonClick={ handleCloseModal } />
		</div>
	);
}
