import { token, userID } from 'constant/localStorage';

const API_URL = process.env.REACT_APP_API_URL;

export async function detailOriginator() {
	try {
		const response = await fetch(`${ API_URL }/registrationdata/${ userID() }`, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				'Authorization': `Bearer ${ token() }`
			},
		});

		if (!response.ok) {
			const errorData = await response.json();
			if (errorData.code === 400 && errorData.code_message === "Error get claim from authorization tokenClaim") {
				window.location.href = "/login";
			} else {
				throw errorData.code_message;
			}
		}
		const data = await response.json();
		localStorage.setItem('kycStatus', data.data.registration_data.kyc_status);
		return data;
	} catch (error) {
		throw error;
	}
}

export async function submitKyc(companyName, companyPhone, companyAddress, skm, selectedFile, bankName, bankAccountName, bankAccount, id) {
	try {
		const response = await fetch(`${ API_URL }/registrationdata/${ id }`, {
			method: "PUT",
			headers: {
				"Content-Type": "application/json",
				"Authorization": `Bearer ${ token() }`
			},
			body: JSON.stringify({
				company: companyName,
				company_phone: companyPhone,
				address: companyAddress,
				registrasi_skm: skm,
				file_license: selectedFile,
				bank_name: bankName,
				bank_account_name: bankAccountName,
				bank_account_number: bankAccount,
				kyc_status: "awaiting"
			})
		});

		if (!response.ok) {
			const errorData = await response.json();
			if (errorData.code === 400 && errorData.code_message === "Error get claim from authorization tokenClaim") {
				window.location.href = "/login";
			} else {
				throw errorData.code_message;
			}
		}
		const data = await response.json();
		localStorage.setItem('kycStatus', data.data.UpdateResult.kyc_status);
		return data;
	} catch (error) {
		throw error;
	}
}

export async function uploadFile(file) {
	try {
		const formData = new FormData();
		formData.append('files', file);

		const response = await fetch(`${ API_URL }/file-upload`, {
			method: 'POST',
			headers: {
				'Authorization': `Bearer ${ token() }`,
			},
			body: formData,
		});

		if (!response.ok) {
			const errorData = await response.json();
			if (errorData.code === 400 && errorData.code_message === "Error get claim from authorization tokenClaim") {
				window.location.href = "/login";
			} else {
				throw errorData.code_message;
			}
		}
		const data = await response.json();
		return data;
	} catch (error) {
		throw error;
	}
}

export async function submitAssets(reporting_data, id) {
	try {
		const response = await fetch(`${ API_URL }/reportings`, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				"Authorization": `Bearer ${ token() }`
			},
			body: JSON.stringify({
				registration_id: id,
				reporting_data: reporting_data
			})
		});

		if (!response.ok) {
			const errorData = await response.json();
			if (errorData.code === 400 && errorData.code_message === "Error get claim from authorization tokenClaim") {
				window.location.href = "/login";
			} else {
				throw errorData.code_message;
			}
		}
		const data = await response.json();
		return data;
	} catch (error) {
		throw error;
	}
}

export async function submitRedeem(redeem_data) {
	try {
		const response = await fetch(`${ API_URL }/redeems`, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				"Authorization": `Bearer ${ token() }`
			},
			body: JSON.stringify({
				financing_no: redeem_data
			})
		});

		if (!response.ok) {
			const errorData = await response.json();
			if (errorData.code === 400 && errorData.code_message === "Error get claim from authorization tokenClaim") {
				window.location.href = "/login";
			} else {
				throw errorData.code_message;
			}
		}
		const data = await response.json();
		return data;
	} catch (error) {
		throw error;
	}
}