import React from "react";
import PropTypes from "prop-types";

const shapes = {
	round: "rounded-lg",
};
const variants = {
	fill: {
		white_A700_01: "bg-white",
		white_A700: "bg-white shadow-sm text-blue_gray-500_01",
		gray_50_02: "bg-gray-50_02",
	},
};
const sizes = {
	xs: "h-[42px] pl-2.5 pr-[35px] text-xs",
	md: "h-[45px] px-[13px] text-xs",
	sm: "h-11 pl-3.5 pr-[35px] text-base",
};

const Input = React.forwardRef(
	(
		{
			className = "",
			name = "",
			placeholder = "",
			type = "text",
			children,
			label = "",
			prefix,
			suffix,
			onChange,
			shape = "round",
			variant = "fill",
			size = "sm",
			color = "white",
			...restProps
		},
		ref,
	) => {
		const handleChange = (e) => {
			if (onChange) onChange(e?.target?.value);
		};

		return (
			<>
				<div
					className={ `${ className } flex items-center justify-center ${ shapes[shape] || "" } ${ variants[variant]?.[color] || variants[variant] || "" } ${ sizes[size] || "" }` }
				>
					{ !!label && label }
					{ !!prefix && prefix }
					<input ref={ ref } type={ type } name={ name } onChange={ handleChange } placeholder={ placeholder } { ...restProps } />
					{ !!suffix && suffix }
				</div>
			</>
		);
	},
);

Input.propTypes = {
	className: PropTypes.string,
	name: PropTypes.string,
	placeholder: PropTypes.string,
	type: PropTypes.string,
	label: PropTypes.string,
	prefix: PropTypes.node,
	suffix: PropTypes.node,
	shape: PropTypes.oneOf(["round"]),
	size: PropTypes.oneOf(["xs", "md", "sm"]),
	variant: PropTypes.oneOf(["fill"]),
	color: PropTypes.oneOf(["white_A700_01", "white_A700", "gray_50_02"]),
};

export { Input };
